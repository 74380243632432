import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '@/containers/Layout'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import NoResultSmiley from '@/assets/icons/NoResultSmiley.inline.svg'

const NotFoundPage = () => {
  const { sanitySiteSettingsGlobalStrings: data } = useStaticQuery(graphql`
    {
      sanitySiteSettingsGlobalStrings {
        errorPageString
      }
    }
  `)

  const errorPageString: string = data.errorPageString

  return (
    <Layout displayNone={true}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box p={5} maxWidth="60rem">
          {errorPageString.split('\n').map(str => <Typography variant="h3" component="p" align="center">
            {str}
          </Typography>)}
        </Box>
        <Box></Box>
        <NoResultSmiley></NoResultSmiley>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
